import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import authReducer from "./features/auth"
import { portierApi } from "../api/portier"

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [portierApi.reducerPath]: portierApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(portierApi.middleware),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
