interface AppConfig {
  auth0: {
    domain: string
    clientId: string
  }
}

const config: AppConfig = {
  auth0: {
    domain: "portier-spider.eu.auth0.com",
    clientId: "0lul1m5ejfjRLWhqjOcPoXj6GMAEzfcj",
  },
}

export default config
