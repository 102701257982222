import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { ApiKey, Device } from "./contract"

export interface TokenParams {
  token?: string
}

export interface DeleteDeviceParams extends TokenParams {
  deviceId: string
}

export interface GetApiKeysParams extends TokenParams {
  deviceId: string
}

export interface CreateApiKeyParams extends TokenParams {
  deviceId: string
  description: string
}

export interface DeleteApiKeyParams extends TokenParams {
  deviceId: string
  apiKeyId: string
}

function buildHeaders(token?: string) {
  return (
    (token && {
      Authorization: `Bearer ${token}`,
    }) ||
    {}
  )
}

export const portierApi = createApi({
  reducerPath: "portierApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://api.portier.dev/api" }),
  tagTypes: ["Devices", "ApiKeys"],
  endpoints: builder => ({
    getDevices: builder.query<Device[], TokenParams>({
      query: args => ({
        url: `/devices`,
        headers: buildHeaders(args.token),
      }),
      providesTags: ["Devices"],
    }),
    deleteDevice: builder.mutation<void, DeleteDeviceParams>({
      query: args => ({
        url: `/device/${args.deviceId}`,
        method: "DELETE",
        headers: buildHeaders(args.token),
      }),
      invalidatesTags: ["Devices"],
    }),
    getApiKeys: builder.query<ApiKey[], GetApiKeysParams>({
      query: args => ({
        url: `/device/${args.deviceId}/apikeys`,
        headers: buildHeaders(args.token),
      }),
      providesTags: ["ApiKeys"],
    }),
    createApiKey: builder.mutation<ApiKey, CreateApiKeyParams>({
      query: args => ({
        url: `/device/${args.deviceId}/apikey`,
        method: "POST",
        body: {
          DeviceGUID: args.deviceId,
          Description: args.description,
        },
        headers: buildHeaders(args.token),
      }),
      invalidatesTags: ["ApiKeys"],
    }),
    deleteApiKey: builder.mutation<void, DeleteApiKeyParams>({
      query: args => ({
        url: `/device/${args.deviceId}/apikeys/${args.apiKeyId}`,
        method: "DELETE",
        headers: buildHeaders(args.token),
      }),
      invalidatesTags: ["ApiKeys"],
    }),
  }),
})

export const {
  useGetDevicesQuery,
  useDeleteDeviceMutation,
  useGetApiKeysQuery,
  useCreateApiKeyMutation,
  useDeleteApiKeyMutation,
} = portierApi
