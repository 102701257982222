import { Auth0Provider } from "@auth0/auth0-react"
import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Provider } from "react-redux"
import config from "../config"
import { store } from "../state/store"

interface RootElementWrapperProps {
  children: React.ReactNode
}

const RootElementWrapper: React.FC<RootElementWrapperProps> = ({
  children,
}) => {
  const data = useStaticQuery(graphql`
    query SiteUrlQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  return (
    <Provider store={store}>
      <Auth0Provider
        domain={config.auth0.domain}
        clientId={config.auth0.clientId}
        authorizationParams={{
          redirect_uri: data.site.siteMetadata.siteUrl + "/devices",
        }}
      >
        {children}
      </Auth0Provider>
    </Provider>
  )
}

export default RootElementWrapper
